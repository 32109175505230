<template>
  <div> 
    <div :class="'table glo-content ' +(noticeShow ? 'glo-magbot' : 'glo-magbot0')">
        <div class="flex-structure">
            <div class="block-part mg-r clo1 glo-relative" @click="toSensor">
              <div class="title">
                 <van-icon name="hotel-o" />
                  <div>传感器</div>
              </div>
              <div class="number">{{info.vib || 0}}</div>
              <div class="link-relative"><van-icon name="arrow" /></div>
            </div>
            <div class="block-part mg-l clo2 glo-relative" @click="toGateway">
              <div class="title">
                  <van-icon name="birthday-cake-o" />
                  <div>网关</div>
              </div>
              <div class="number">{{info.gw || 0}}</div>
              <div class="link-relative"><van-icon name="arrow" /></div>
            </div>
        </div>
        <van-divider :style="{ color: '#2cb5e8', borderColor: '#2cb5e8', padding: '0 16px', margin:'0 0 12px 0' }">真实桥梁照片集展示</van-divider>
        <div class="banner glo-relative">
            <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white">
              <van-swipe-item v-for="item in swipeImg" :key="item">
                  <img :src="item">
              </van-swipe-item>
            </van-swipe>
        </div>
        <van-row class="tb-head">
          <van-col span="6">监测区域</van-col>
          <van-col span="6">倾角(°)</van-col>
          <van-col span="6">挠度(mm)</van-col>
          <van-col span="6">状态</van-col>
        </van-row>
        <div v-if="loading">
            <van-loading type="spinner" vertical>加载中</van-loading>
        </div>
        <div v-else>
            <div v-if="areaData.length == 0">
                <van-divider>暂无记录</van-divider>
            </div>
            <div v-else>
              <van-row class="tb-body" v-for="(item,index) in areaData" :key="index">
                <van-col span="6">{{item.showName || '暂无名称'}}</van-col>
                <van-col span="6">{{item.xaxisangle}}</van-col>
                <van-col span="6">{{(item.vibamp / 1).toFixed(2)}}</van-col>
                <van-col span="6">
                  <van-tag type="success" v-if="item.status == '0'">正常</van-tag>
                  <van-tag type="danger" v-else>告警</van-tag>
                </van-col>
              </van-row>
            </div>
        </div>
    </div>
    <tabbar />
  </div>
</template>
<script>
import tabbar from '@/components/bridge/LHtabbar.vue'
export default {
  components: {
    tabbar
  },
  props: ['magbot'],
  data() {
    return {
      bridgeCode:'',
      areaData:[],
      swipeImg:[
        require('@/assets/bridge/img/img1.jpg'),
        require('@/assets/bridge/img/img2.jpg'),
        require('@/assets/bridge/img/img3.jpg'),
        require('@/assets/bridge/img/img4.jpg'),
      ],
      loading:true,
      magbotVal:'',
      info:{
        gw:'',
        vib:''
      }
    }
  },
  watch: {
      magbot: function (val) {   
        this.magbotVal = val;
      }
  },
  methods:{
    // 获取实时监控列表
    loadList(){
      this.$api.BRIDGE.realTimeList({
        bridgeCode:this.bridgeCode
      }).then(d=>{
        if(Array.isArray(d)){
            this.areaData = d.filter( e => { return e.xaxisangle});
            this.loading = false;
        }
      })
    },
    // 获取网关和传感器数量
    devCountInfo(){
      this.$api.BRIDGE.getDevCount({
        bridgeCode:this.bridgeCode
      }).then(d => {
        this.info.gw = d.gw;
        this.info.vib = d.vib;
      })
    },
    // 跳转到传感器页面
    toSensor(){
      this.$router.push({
        path:'/bridge/lighthouse/sensor'
      })
    },
    // 跳转到网关页面
    toGateway(){
      this.$router.push({
        path:'/bridge/lighthouse/gateway'
      })
    }
  },
  computed:{
    noticeShow(){
      return window.sessionStorage.getItem('magbot') == '1' && this.magbot != 1 ? true : false
    }
  },
  mounted(){
      this.bridgeCode = window.sessionStorage.getItem('bridgeCode');
      if(this.bridgeCode){
        this.devCountInfo();
        this.loadList();
      }
  }
}
</script>
<style lang="scss" scoped>
.flex-structure{
  display: flex;
  padding-bottom: 4%;
  .block-part{
    color: #fff;
    width: 50%;
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-self: center;
    line-height: 1.8;
    box-shadow: 2px 2px 2px #ccc;
    .van-icon{font-size: 2rem;}
    .title{font-size: .75rem;display: flex;flex-direction: column;align-self: center;}
    .number{font-size: 3rem;padding-left: 4%;display: flex;align-items: center;}
  }
  .clo1{background-color: #2cb5e8;}
  .clo2{background-color: #5fe0d4;}
  .mg-l{margin-left: 2%;}
  .mg-r{margin-right: 2%;}
}
.glo-relative{
  .link-relative{
    position: absolute;
    top: 38%;
    right: 4%;
    .van-icon{
      font-size: .8rem !important;
    }
  }
}
.banner{
  img{
    width: 100%;
    height: 12rem;
    border-radius: 10px;
  }
}
.table{
  .tb-head{
    font-weight: bold;font-size: .95rem;
    background-color: #2cb5e8;
    color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 3%;
    div{
      line-height: 2;
      padding: 8px 0;
    }
  }
  .tb-body{
    font-size: .85rem;
    border-bottom: 1px solid #fff;
    background-color: #c6f5e8;
    color: #464646;
    div{
      line-height: 2;
      padding: 8px 0;
    }
  }
}
</style>